import React, { useEffect, useRef, useState } from "react";
import styles from "./styles/PopUpVoucher.module.css";
import styles2 from "./styles/PopUpTicket.module.css";
import PopUp2 from "./PopUp2";
import {
  BiArrowBack,
  BiCalendar,
  BiCard,
  BiCopy,
  BiFilter,
  BiGrid,
  BiInfoCircle,
  BiPlusCircle,
  BiSolidDiscount,
  BiTrash,
} from "react-icons/bi";
import Select from "react-select";
import InputForm from "../components/InputForm";
import InputLabeled from "../components/InputLabeled";
import FieldBox from "../components/FieldBox";
import axios from "axios";
import Loading from "../components/Loading";
import moment from "moment";
import Alert from "../components/Alert";
import InputToogle from "../components/InputToogle";
import PopUp2Factor from "./PopUp2Factor";
import JWT from "expo-jwt";
import { SupportedAlgorithms } from "expo-jwt/dist/types/algorithms";

const handleSuccess = (res) => {
  return {
    data: res.data,
    status: res.status,
  };
};

const handleError = (error) => {
  // console.log(error);
  if (error.response === undefined) {
    return {
      data: { data: [error.message] },
      status: 500,
    };
  } else {
    return {
      data: error.response,
      status: error.response.status,
    };
  }
};

const addCustomUrl = async ({ regex, orgId, eventId, token, email }) => {
  try {
    let jwtToken = JWT.encode(
      {
        client_id: process.env.REACT_APP_ID,
        email_user: email,
        timestamp: new Date().getTime(),
      },
      process.env.REACT_APP_BACKEND_KEY,
      { algorithm: SupportedAlgorithms.HS256 }
    );
    let res = await axios.post(
      process.env.REACT_APP_BACKEND_URL +
        `/api/org/${orgId}/event/${eventId}/manage/custom-url/create`,
      {
        str_custom: regex,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
          "x-api-key": jwtToken,
        },
      }
    );
    return handleSuccess(res);
  } catch (error) {
    return handleError(error);
  }
};

const PopUpCustomUrl = ({
  isPopActive,
  setPopUpActive = () => {},
  titlePopUp,
  customUrlData = null,
  orgId,
  eventId,
  isLogin,
  token,
  fnSetLogin,
}) => {
  // ===== State control =====
  const [blankRegex, setBlankRegex] = useState(false);
  const [alert, setAlert] = useState({
    state: false,
    type: "",
    content: "",
  });
  const [loading, setLoading] = useState(false);
  const [pausedProcess, setPausedProcess] = useState({
    state: false,
    name: "",
    data: null,
  });
  const [isVerified, setVerify] = useState(true);
  const [verifyMsg, setVerifyMsg] = useState(
    "Mohon maaf. Kode OTP bisa didapatkan kembali minimal 2 menit dari permintaan terakhir."
  );

  // ===== Form data ======
  const regex = useRef();

  const setVerified = ({ state, msg }) => {
    setVerify(state);
    if (msg) {
      setVerifyMsg(msg);
    }
  };

  const onTypeRegex = (e) => {
    if (
      e.target.value &&
      e.target.value.match(/[a-zA-Z0-9-_]/g).length !== e.target.value.length
    ) {
      setBlankRegex(true);
      setAlert({
        state: true,
        type: "danger",
        content:
          "Custom URL hanya menerima karakter huruf, angka. (-), dan (_) !",
      });
    } else {
      setBlankRegex(false);
      setAlert({
        state: false,
        type: "",
        content: "",
      });
    }
  };

  const basicValidator = () => {
    let error = null;
    if (
      !regex.current ||
      regex.current.value === "" ||
      regex.current.value === " " ||
      regex.current.value.length > 254 ||
      regex.current.value.match(/[a-zA-Z0-9-_]/g).length !==
        regex.current.value.length
    ) {
      setBlankRegex(true);
      error =
        !regex.current ||
        regex.current.value === "" ||
        regex.current.value === " "
          ? "Custom url form wajib diisi !"
          : "Custom url maksimal 254 karakter. Dan hanya menerima karakter huruf, angka. (-), dan (_) !";
      setAlert({
        state: true,
        type: "danger",
        content: error,
      });
    }
    return error;
  };

  const handleSaveBtn = (regexParam) => {
    if (!basicValidator()) {
      addCustomUrl({
        regex: regexParam,
        orgId,
        eventId,
        token,
        email: JSON.parse(localStorage.getItem("user-data")).email,
      }).then((res) => {
        if (res.status === 202) {
          setAlert({
            state: true,
            type: "success",
            content:
              "Custom URL berhasil disimpan. Klik tutup untuk menutup editor !",
          });
        } else if (res.status === 401) {
          setPausedProcess({
            state: true,
            name: "add",
            data: regex.current.value,
          });
          fnSetLogin(false);
        } else if (res.status === 405) {
          setVerified({
            state: false,
            msg:
              res.data.data.message ===
              "Mohon lakukan verifikasi kembali. Kode OTP telah dikirimkan ke email anda"
                ? res.data.data.message
                : null,
          });
          setPausedProcess({
            state: true,
            name: "add",
            data: regex.current.value,
          });
        } else {
          setAlert({
            state: true,
            type: "danger",
            content:
              res.status === 500
                ? "Data gagal di simpan. Silahlan ulangi lagi"
                : Object.values(res.data.data).reduce((current, acc) => {
                    return (
                      (current === "" ? current : current + ", ") +
                      (Array.isArray(acc) ? acc.join(", ") : acc)
                    );
                  }, ""),
          });
        }
      });
    }
  };

  useEffect(() => {
    if (pausedProcess.state && isLogin && isVerified) {
      handleSaveBtn(pausedProcess.data);
      if (isVerified) {
        setPausedProcess({
          state: false,
          name: "",
          data: null,
        });
      }
    }
  }, [pausedProcess, isVerified, isLogin]);

  return (
    <>
      {!isVerified ? (
        <PopUp2Factor
          setLogin={() => {}}
          verifyState={verifyMsg}
          setVerify={setVerified}
          token={token}
        />
      ) : (
        <></>
      )}
      <div style={!isVerified ? { display: "none" } : {}}>
        <PopUp2
          width="928px"
          isActive={isPopActive && titlePopUp === "custom-url"}
          setActiveFn={() => {
            handleSaveBtn(regex.current.value);
          }}
          closeBtnAbs={{
            title: "Tutup",
            fn: () => {
              setPopUpActive(false);
            },
          }}
          classNames={{
            wrapper: [styles2.PopUpWrapper2],
            modalDialog: [styles2.ModalDialog],
            popUpBox: [styles2.PopUpBox],
            header: [],
            content: [styles2.PopUpContent],
          }}
          closeBtnTitle={"Simpan"}
          titleHeader={<div className={styles2.PopUpHeader}>Custom Url</div>}
          content={
            <>
              <Alert
                type={alert.type}
                isShow={alert.state}
                setShowFn={() => {
                  setAlert({
                    state: false,
                    type: "",
                    content: "",
                  });
                }}
                message={alert.content}
                closeBtn={true}
                className={[styles.Alert]}
              />
              {loading ? (
                <div style={{ padding: "50px", width: "100%" }}>
                  <Loading />
                </div>
              ) : (
                <></>
              )}
              <div
                className={styles.PopUpContent}
                style={loading ? { display: "none" } : {}}
              >
                <div
                  className={styles2.BottomContainer}
                  style={{ paddingBottom: "10px" }}
                >
                  <div
                    className={styles2.Info2}
                    style={{
                      marginLeft: "0px",
                      marginRight: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    <div className={styles2.CmdField}>
                      <BiInfoCircle />
                    </div>
                    <p></p>
                    <ol>
                      <b style={{ marginLeft: "-15px", marginBottom: "10px" }}>
                        Apa itu custom URL ?
                      </b>
                      <li>
                        Custom URL merupakan fitur untuk mengubah URL dari
                        detaill event sesuai kebutuhan organizer
                      </li>
                      <li>
                        Organizer dapat memperpendek URL dari detail eventnya
                      </li>
                      <li>
                        Maksimal panjang karakter yang dapat diinputkan yaitu
                        254 karakter
                      </li>
                    </ol>
                  </div>
                  <InputLabeled
                    type={"text"}
                    id={"code"}
                    placeholder={"XXXX"}
                    className={[
                      styles.ForceLeft,
                      blankRegex ? styles2.DangerInput : "",
                    ]}
                    iconSvg={<BiCard />}
                    label={
                      <p
                        className={styles2.TextSecondary}
                        style={blankRegex ? { color: "red" } : {}}
                      >
                        agendakota.id/
                      </p>
                    }
                    refData={regex}
                    fnOnInput={(e) => {
                      setBlankRegex(false);
                      onTypeRegex(e);
                    }}
                    value={customUrlData ? customUrlData : null}
                  />
                </div>
              </div>
            </>
          }
        />
      </div>
    </>
  );
};

export default PopUpCustomUrl;
