import React, { useState, useEffect, useRef } from "react";
import styles from "./styles/PopUpLogin.module.css";
import stylesLogin from "../Pages/styles/Login.module.css";
import InputForm from "../components/InputForm";
import Button from "../components/Button";
import Alert from "../components/Alert";
import axios from "axios";
import {
  BiEnvelope,
  BiInfoCircle,
  BiKey,
  BiLock,
  BiSolidKey,
  BiUser,
} from "react-icons/bi";
import Loading from "../components/Loading";
import { useDispatch } from "react-redux";
import { getAppData } from "../actions/appdata";
import PopUpLogin from "./PopUpLogin";
// import ReCAPTCHA from "react-google-recaptcha";
import JWT from "expo-jwt";
import { SupportedAlgorithms } from "expo-jwt/dist/types/algorithms";

const loadOrganizers = async ({ token, OTP, email }) => {
  try {
    let jwtToken = JWT.encode(
      {
        client_id: process.env.REACT_APP_ID,
        email_user: email,
        timestamp: new Date().getTime(),
      },
      process.env.REACT_APP_BACKEND_KEY,
      { algorithm: SupportedAlgorithms.HS256 }
    );
    let res = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/api/org/user-orgs?otp_2fa=" + OTP,
      {
        headers: {
          Authorization: "Bearer " + token,
          "x-api-key": jwtToken,
        },
      }
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    // console.log(error);
    if (error.response === undefined) {
      return {
        data: { data: [error.message] },
        status: 500,
      };
    } else {
      return {
        data: error.response,
        status: error.response.status,
      };
    }
  }
};

const reqOtp = async ({ token, email }) => {
  try {
    let jwtToken = JWT.encode(
      {
        client_id: process.env.REACT_APP_ID,
        email_user: email,
        timestamp: new Date().getTime(),
      },
      process.env.REACT_APP_BACKEND_KEY,
      { algorithm: SupportedAlgorithms.HS256 }
    );
    let res = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/api/request-two-factor",
      {},
      {
        headers: {
          Authorization: "Bearer " + token,
          "x-api-key": jwtToken,
        },
      }
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    // console.log(error);
    if (error.response === undefined) {
      return {
        data: { data: [error.message] },
        status: 500,
      };
    } else {
      return {
        data: error.response,
        status: error.response.status,
      };
    }
  }
};

const PopUp2Factor = ({
  setLogin,
  verifyState,
  setVerify,
  addtionalStyle = {},
  token,
}) => {
  // state control
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [isLoginInner, setLoginInner] = useState(true);
  const [haveReqOTP, setHaveReqState] = useState(false);
  const [innerToken, setInnerToken] = useState(token);
  const [pausedProcess, setPausedProcess] = useState({
    state: false,
    name: "",
    data: null,
  });

  // field data basic
  const [inputFocus, setInputFocus] = useState(null);
  // const [captcha, setCaptchaState] = useState(null);
  const fieldLogin = {
    otp: useRef(null),
  };
  const dispatch = useDispatch();

  const handleSubmit = async (OTP) => {
    const fnSetLoading = (state) => {
      if (OTP === "") {
        setLoading2(state);
      } else {
        setLoading(state);
      }
    };
    fnSetLoading(true);
    loadOrganizers({
      token: innerToken,
      OTP,
      email: JSON.parse(localStorage.getItem("user-data")).email,
    }).then((res) => {
      // console.log(res);
      if (res.status === 200 || res.status === 404) {
        setLogin(false);
        setTimeout(() => {
          setVerify({
            state: true,
            msg: "Mohon lakukan verifikasi kembali. Kode OTP telah dikirimkan ke email anda",
          });
          dispatch(
            getAppData({
              accessToken: token,
              activeOrg: localStorage.getItem("active-org"),
              activeEvent: localStorage.getItem("active-event"),
              userData: JSON.parse(localStorage.getItem("user-data")),
            })
          );
          setLogin(true);
          fnSetLoading(false);
        }, 50);
      } else if (res.status === 401) {
        setLoginInner(false);
        setPausedProcess({
          state: true,
          name: "verify",
          data: OTP,
        });
      } else if (res.status === 405) {
        setVerify({
          state: false,
          msg: res.data.data.message,
        });
        setLogin(false);
        fnSetLoading(false);
        if (
          res.data.data.message === "Kode autentikasi akan dikirim otomatis"
        ) {
          setHaveReqState(false);
        }
      } else {
        setVerify({
          state: false,
          msg: "Terjadi masalah dengan server. Silakan coba lagi",
        });
        setLogin(false);
        fnSetLoading(false);
      }
    });
  };

  const handleReqOtp = ({ innerToken }) => {
    reqOtp({
      token: innerToken,
      email: JSON.parse(localStorage.getItem("user-data")).email,
    }).then((res) => {
      if (res.status === 401) {
        setLoginInner(false);
        setPausedProcess({
          state: true,
          name: "req-otp",
          data: null,
        });
      } else if (res.status === 405) {
        setVerify({
          state: false,
          msg: res.data.data.message,
        });
        setLogin(false);
      } else {
        setVerify({
          state: false,
          msg: "Terjadi masalah dengan server. Silakan minta kode OTP lagi",
        });
        setLogin(false);
      }
      setLoading2(false);
      setHaveReqState(true);
    });
  };

  useEffect(() => {
    if (!haveReqOTP) {
      setLoading2(true);
      handleReqOtp({ innerToken: innerToken });
    }
  }, [haveReqOTP]);

  useEffect(() => {
    if (innerToken && isLoginInner && pausedProcess.state) {
      const fnSetLoading = (state) => {
        if (pausedProcess.data === "") {
          setLoading2(state);
        } else {
          setLoading(state);
        }
      };
      fnSetLoading(true);
      if (pausedProcess.name === "verify") {
        loadOrganizers({
          token: innerToken,
          OTP: pausedProcess.data,
          email: JSON.parse(localStorage.getItem("user-data")).email,
        }).then((res) => {
          // console.log(res);
          localStorage.setItem("access_token", innerToken);
          dispatch(
            getAppData({
              accessToken: innerToken,
              activeOrg: localStorage.getItem("active-org"),
              activeEvent: localStorage.getItem("active-event"),
              userData: JSON.parse(localStorage.getItem("user-data")),
            })
          );
          if (res.status === 200 || res.status === 404) {
            setLogin(false);
            setTimeout(() => {
              setVerify({
                state: true,
                msg: "Mohon lakukan verifikasi kembali. Kode OTP telah dikirimkan ke email anda",
              });
              setLogin(true);
            }, 50);
          } else if (res.status === 401) {
            setLoginInner(false);
            setPausedProcess({
              state: true,
              name: "verify",
              data: pausedProcess.data,
            });
          } else if (res.status === 405) {
            setVerify({
              state: false,
              msg: res.data.data.message,
            });
            setLogin(false);
            if (
              res.data.data.message === "Kode autentikasi akan dikirim otomatis"
            ) {
              setHaveReqState(false);
            }
          } else {
            setVerify({
              state: false,
              msg: "Terjadi masalah dengan server. Silakan coba lagi",
            });
            setLogin(false);
          }
          fnSetLoading(false);
        });
      } else if (pausedProcess === "req-otp") {
        handleReqOtp({ innerToken: innerToken });
      }

      setPausedProcess({
        state: false,
        name: "",
        data: null,
      });
    }
  }, [innerToken, pausedProcess]);

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (e.target === fieldLogin.otp.current) {
        setInputFocus("otp");
      }
    });
  });

  return !isLoginInner ? (
    <PopUpLogin
      setLogin={({ state, token }) => {
        setLoginInner(state);
        setInnerToken(token);
      }}
      forTwoAuth={true}
    />
  ) : (
    <div className={styles.Wrapper} style={{ ...addtionalStyle }}>
      <div className={styles.ModalDialog}>
        <div className={styles.PopUpBox}>
          <div className={stylesLogin.Content} style={{ marginTop: 0 }}>
            {loading2 || !haveReqOTP ? (
              <div style={{ marginTop: "140px", marginBottom: "100px" }}>
                <Loading />
              </div>
            ) : (
              <div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(fieldLogin.otp.current.value);
                  }}
                >
                  <div className={styles.AlertBox}></div>
                  <div className={styles.Logo}>
                    <img src="/images/logo.png" alt="" />
                  </div>
                  <div className={styles.AlertBox}>
                    {/* {console.log("Verofy State ", verifyState)} */}
                    <Alert
                      type={
                        verifyState ===
                        "Mohon lakukan verifikasi kembali. Kode OTP telah dikirimkan ke email anda"
                          ? "success"
                          : "danger"
                      }
                      isShow={verifyState ? true : false}
                      setShowFn={() => {}}
                      message={verifyState}
                      closeBtn={false}
                    />
                  </div>
                  <div
                    className={`${stylesLogin.SubTitle} ${stylesLogin.TextCenter}`}
                    style={{ marginTop: "5px" }}
                  >
                    Two Factor Verification
                  </div>

                  <div>
                    <div
                      className={styles.InfoBox}
                      style={{ marginTop: "20px" }}
                    >
                      <BiInfoCircle />
                      <div className={styles.InfoText}>
                        Silhakan masukkan kode OTP yang anda peroleh melalui
                        email, untuk mengakses akun anda.
                      </div>
                    </div>
                    <div
                      className={`${stylesLogin.BoxInput} ${
                        inputFocus === "otp" ? stylesLogin.ShadowBoxInput2 : ""
                      }`}
                    >
                      <div className={stylesLogin.FormFieldInput}>
                        <label
                          className={stylesLogin.TitleInput}
                          style={{
                            color:
                              inputFocus === "otp"
                                ? "rgb(202, 12, 100)"
                                : "#000",
                          }}
                          htmlFor="otp-input"
                          onFocus={() => {
                            setInputFocus("otp");
                          }}
                        >
                          <BiSolidKey />
                          <div>OTP</div>
                        </label>
                        <InputForm
                          id={"otp-input"}
                          className={stylesLogin.FieldInput}
                          refData={fieldLogin.otp}
                          type={"text"}
                          required
                          placeholder={"Tuliskan kode OTP anda"}
                        />
                      </div>
                    </div>

                    {/* <div className={stylesLogin.CapcthaField}>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                      onChange={(value) => {
                        setCaptchaState(value);
                      }}
                      style={{ margin: "auto" }}
                    />
                  </div> */}

                    <div
                      className={styles.RegisterButton}
                      style={{ marginTop: "10px" }}
                      onClick={() => {
                        setHaveReqState(false);
                      }}
                    >
                      Belum menerima OTP ? Minta OTP
                    </div>

                    <div className={styles.FormFieldBox}>
                      {loading ? (
                        <Button
                          bgColor={"rgb(212 132 169)"}
                          borderColor={"rgb(212 132 169)"}
                          title={"Loading ..."}
                          icon={
                            <div
                              className="spinner-border"
                              style={{ width: "20px", height: "20px" }}
                              animation="border"
                            />
                          }
                          center={true}
                          style={{ width: "100%", textAlign: "center" }}
                        />
                      ) : (
                        <Button
                          title={"Verifikasi"}
                          typeBtn="submit"
                          classes={[styles.FormButton]}
                          style={{ width: "100%", margin: "auto" }}
                        />
                      )}
                      <div
                        style={{
                          flexDirection: "row",
                          margin: "auto",
                          gap: "10px",
                        }}
                      ></div>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUp2Factor;
