import React, { useEffect, useRef, useState } from "react";
import SidebarUser from "../../partials/SidebarUser";
import HeaderUser from "../../partials/HeaderUser";
import styles from "./styles/PersonalEvent.module.css";
import styles2 from "../Organizer/styles/Legality.module.css";
import styles3 from "./styles/Settings.module.css";
import Button from "../../components/Button";
import Alert from "../../components/Alert";
import InputForm from "../../components/InputForm";
import Loading from "../../components/Loading";
import PopUp from "../../partials/PopUp";
import { BiCheckCircle, BiError } from "react-icons/bi";
import axios from "axios";
import { useSelector } from "react-redux";
import InputToogle from "../../components/InputToogle";
import InputCheckRadio from "../../components/InputCheckRadio";
import FieldBox from "../../components/FieldBox";
import ErrorPage from "../../partials/ErrorPage";
import JWT from "expo-jwt";
import { SupportedAlgorithms } from "expo-jwt/dist/types/algorithms";

const handleSuccess = (res) => {
  return {
    data: res.data,
    status: res.status,
  };
};

const handleError = (error) => {
  // console.log(error);
  if (error.response === undefined) {
    return {
      data: { data: [error.message] },
      status: 500,
    };
  } else {
    return {
      data: error.response,
      status: error.response.status,
    };
  }
};

const loadProfile = async ({ token, email }) => {
  try {
    let jwtToken = JWT.encode(
      {
        client_id: process.env.REACT_APP_ID,
        email_user: email,
        timestamp: new Date().getTime(),
      },
      process.env.REACT_APP_BACKEND_KEY,
      { algorithm: SupportedAlgorithms.HS256 }
    );
    let res = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/api/profile",
      {
        headers: {
          Authorization: "Bearer " + token,
          "x-api-key": jwtToken,
        },
      }
    );
    return handleSuccess(res);
  } catch (error) {
    return handleError(error);
  }
};

const updatePassword = async ({
  newPass,
  lastPass,
  confirmPass,
  token,
  email,
}) => {
  try {
    let jwtToken = JWT.encode(
      {
        client_id: process.env.REACT_APP_ID,
        email_user: email,
        timestamp: new Date().getTime(),
      },
      process.env.REACT_APP_BACKEND_KEY,
      { algorithm: SupportedAlgorithms.HS256 }
    );
    let res = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/api/update-password",
      {
        _method: "PUT",
        last_password: lastPass,
        new_password: newPass,
        confirm_password: confirmPass,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
          "x-api-key": jwtToken,
        },
      }
    );
    return handleSuccess(res);
  } catch (error) {
    return handleError(error);
  }
};

const updateSeecurity = async ({ state, token, email }) => {
  try {
    let jwtToken = JWT.encode(
      {
        client_id: process.env.REACT_APP_ID,
        email_user: email,
        timestamp: new Date().getTime(),
      },
      process.env.REACT_APP_BACKEND_KEY,
      { algorithm: SupportedAlgorithms.HS256 }
    );
    let res = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/api/set-two-factor",
      {
        _method: "PUT",
        enable_two_factor: state,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
          "x-api-key": jwtToken,
        },
      }
    );
    return handleSuccess(res);
  } catch (error) {
    return handleError(error);
  }
};

const Setting = ({
  isLogin,
  fnSetLogin = () => {},
  fnSetVerify = () => {},
}) => {
  const [alertDanger, setAlertDanger] = useState({
    state: false,
    content: "Semua field wajib diisi !",
  });
  const [isLoading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [popUpActive, setPopUpActive] = useState(false);
  const [popUpContent, setPopUpContent] = useState(<></>);
  const [pausedProcess, setPausedProcess] = useState(null);
  const [errorState, setErrorState] = useState(false);
  const appData = useSelector((state) => state.appDataReducer);

  const fieldProfile = {
    lastPass: useRef(null),
    newPass: useRef(null),
    confirmPass: useRef(null),
  };

  const dummyLoad = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(false);
      }, 3000);
    });
  };

  const handleSubmit = (token) => {
    if (
      fieldProfile.newPass.current.value === "" ||
      fieldProfile.confirmPass.current.value === "" ||
      fieldProfile.newPass.current.value !==
        fieldProfile.confirmPass.current.value ||
      fieldProfile.newPass.current.value.split("").length < 8 ||
      fieldProfile.confirmPass.current.value.split("").length < 8
    ) {
      setAlertDanger({
        state: true,
        content:
          fieldProfile.newPass.current.value !==
          fieldProfile.confirmPass.current.value
            ? "Konfirmasi password tidak sesuai dengan password baru !"
            : fieldProfile.newPass.current.value.split("").length < 8 ||
              fieldProfile.confirmPass.current.value.split("").length < 8
            ? "Password minimal 8 karakter"
            : "Semua field selain password lama wajib diisi !!!",
      });
      setTimeout(() => {
        setAlertDanger({
          state: false,
          content: "Semua field selain password lama wajib diisi !!!",
        });
      }, 3000);
    } else {
      setLoading(true);
      updatePassword({
        lastPass: fieldProfile.lastPass.current.value,
        newPass: fieldProfile.newPass.current.value,
        confirmPass: fieldProfile.confirmPass.current.value,
        token: token,
        email: appData.userData ? appData.userData.email : "",
      }).then((res) => {
        if (res.status === 202) {
          setPopUpContent(
            <div className={styles3.PopupNotify}>
              <div className={styles3.IconPopUp}>
                <BiCheckCircle color="green" fontWeight={"600"} />
              </div>
              <div>Password berhasil diperbarui</div>
              <Button
                title={"Ok"}
                fnOnClick={() => {
                  setPopUpActive(false);
                }}
              />
            </div>
          );
          setPopUpActive(true);
          fieldProfile.newPass.current.value = "";
          fieldProfile.confirmPass.current.value = "";
          fieldProfile.lastPass.current.value = "";
          setLoading(false);
        } else if (
          (res.status === 401 &&
            res.data.data.message === "Unauthenticated.") ||
          res.status === 405
        ) {
          if (res.status === 405) {
            fnSetVerify({
              state: false,
              msg:
                res.data.data.message ===
                "Mohon lakukan verifikasi kembali. Kode OTP telah dikirimkan ke email anda"
                  ? res.data.data.message
                  : null,
            });
          }
          setPausedProcess("update");
          fnSetLogin(false);
        } else {
          setPopUpContent(
            <div className={styles3.PopupNotify}>
              <div className={styles3.IconPopUp}>
                <BiError color="#CA0C64" fontWeight={"600"} />
              </div>
              <div>
                Password gagal diperbarui. Pastikan password lama anda sudah
                benar. Silahkan coba lagi !
              </div>
              <Button
                title={"Ok"}
                fnOnClick={() => {
                  setPopUpActive(false);
                }}
              />
            </div>
          );
          setPopUpActive(true);
          setLoading(false);
        }
      });
    }
  };

  const handleSetSecurity = (state, token) => {
    setLoading(true);
    updateSeecurity({
      token,
      state,
      email: appData.userData ? appData.userData.email : "",
    }).then((res) => {
      if (res.status === 202) {
        setPopUpContent(
          <div className={styles3.PopupNotify}>
            <div className={styles3.IconPopUp}>
              <BiCheckCircle color="green" fontWeight={"600"} />
            </div>
            <div>Pengaturan Keamanan Telah diperbarui</div>
            <Button
              title={"Ok"}
              fnOnClick={() => {
                setPopUpActive(false);
              }}
            />
          </div>
        );
        setPopUpActive(true);
        setLoading(false);
        let profile = profileData;
        profile.two_factor = state ? 1 : 0;
        setProfileData(profile);
      } else if (res.status === 401 || res.status === 405) {
        if (res.status === 405) {
          fnSetVerify({
            state: false,
            msg:
              res.data.data.message ===
              "Mohon lakukan verifikasi kembali. Kode OTP telah dikirimkan ke email anda"
                ? res.data.data.message
                : null,
          });
        }
        setPausedProcess("update-security@" + state);
        fnSetLogin(false);
      } else {
        setPopUpContent(
          <div className={styles3.PopupNotify}>
            <div className={styles3.IconPopUp}>
              <BiError color="#CA0C64" fontWeight={"600"} />
            </div>
            <div>
              Pengaturan keamanan gagal diperbarui. Silahkan coba lagi !
            </div>
            <Button
              title={"Ok"}
              fnOnClick={() => {
                setPopUpActive(false);
              }}
            />
          </div>
        );
        setPopUpActive(true);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (!profileData && appData.accessToken) {
      setLoading(true);
      loadProfile({
        token: appData.accessToken,
        email: appData.userData ? appData.userData.email : "",
      }).then((res) => {
        if (res.status === 200) {
          setProfileData(res.data.user);
        } else if (res.status === 401 || res.status === 405) {
          if (res.status === 405) {
            fnSetVerify({
              state: false,
              msg:
                res.data.data.message ===
                "Mohon lakukan verifikasi kembali. Kode OTP telah dikirimkan ke email anda"
                  ? res.data.data.message
                  : null,
            });
          }
          fnSetLogin(false);
        } else {
          setErrorState(true);
        }
        setLoading(false);
      });
    }
  }, [profileData, appData]);

  useEffect(() => {
    if (pausedProcess && isLogin) {
      if (pausedProcess === "update") {
        handleSubmit(appData.accessToken);
      } else if (pausedProcess.split("@")[0] === "update-security") {
        handleSetSecurity(
          pausedProcess.split("@")[1] == "true" ? true : false,
          appData.accessToken
        );
      }
      setPausedProcess(null);
    }
  }, [pausedProcess, isLogin]);

  useEffect(() => {
    document.title = "Setting - Agendakota";
  });

  return (
    <>
      <PopUp
        width="45%"
        isActive={popUpActive}
        setActiveFn={setPopUpActive}
        content={popUpContent}
        title="Ubah Password"
      />

      <div className="content user">
        {errorState ? (
          <ErrorPage />
        ) : (
          <>
            <div className={styles.DecorationBox}>
              <div className={styles.Decoration}></div>
            </div>
            <div className={styles.Inline} style={{ marginTop: 20 }}>
              <div
                style={{
                  margin: "auto",
                  marginTop: "150px",
                  display: isLoading ? "unset" : "none",
                }}
              >
                <Loading />
              </div>
              <div style={{ display: isLoading ? "none" : "unset" }}>
                <div className={styles2.FormHeader}>
                  <div className={styles2.TitleArea}>
                    <h1 className={styles.Title}>Keamanan</h1>
                  </div>
                </div>
                <div className={styles2.FormFieldBox}>
                  <div className={styles2.FormFooter}>
                    <div>INFORMASI FITUR</div>
                    <br />
                    <ol>
                      <li>
                        Autentikasi Dua Faktor merupakan opsi keamanan yang kami
                        sediakan untuk meningkatkan keamanan akun anda. Jika
                        anda mengaktifkan fitur ini, maka anda akan diminta
                        untuk menginputkan kode OTP (Dikirim via email akun)
                        setiap kali anda berpindah jaringan internet, dan saat
                        pertama setelah pengaktifkan fitur ini.
                        <br />
                      </li>
                      <li>
                        Selain sebagai pengaman akun, fitur ini juga sebagai
                        indikator apakah ada aktivitas mencurigakan di akun
                        anda. Jika anda terlalu sering diminta menginputkan kode
                        OTP padahal anda tidak berpindah jaringan internet. Maka
                        ada kemungkinan password anda telah diketahui seseorang.
                        Jadi segeralah mengganti password akun dan email anda.
                      </li>
                    </ol>
                  </div>
                  <FieldBox
                    style={{ backgroundColor: "#fff" }}
                    label={"Aktifkan Autentikasi Dua Faktor"}
                  >
                    <InputToogle
                      style={{ marginLeft: "auto" }}
                      checked={profileData && profileData.two_factor == 1}
                      onInput={(e) => {
                        handleSetSecurity(
                          e.target.checked,
                          appData.accessToken
                        );
                      }}
                      // refData={publishToogle}
                    />
                  </FieldBox>
                </div>
              </div>
              {/* ========================================================================================================================== */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(appData.accessToken);
                }}
                className={`${styles2.FormLayout}`}
                style={{
                  marginTop: "-10px",
                }}
              >
                <div
                  className={styles2.FormHeader}
                  style={{ display: isLoading ? "none" : "flex" }}
                >
                  <div className={styles2.TitleArea}>
                    <h1 className={styles.Title}>Atur Ulang Password</h1>
                  </div>
                  <div className={styles2.HeaderControl}>
                    <Button
                      title={"Ubah Password"}
                      typeBtn="submit"
                      classes={[styles2.FormButton]}
                    />
                  </div>
                </div>

                <div
                  className={styles3.ProfileLayout}
                  style={{ display: isLoading ? "none" : "unset" }}
                >
                  <div className={styles2.AlertBox}>
                    <Alert
                      type="danger"
                      isShow={alertDanger.state}
                      setShowFn={() => {}}
                      message={alertDanger.content}
                    />
                  </div>
                  <div className={styles2.FormFieldBox}>
                    <div className={styles2.TitleInput}>Password Lama</div>
                    <div className={styles3.FormFieldInput}>
                      <InputForm
                        refData={fieldProfile.lastPass}
                        type={"password"}
                        placeholder={"Tuliskan password lama kamu"}
                      />
                    </div>
                  </div>
                  <div className={styles2.FormFieldBox}>
                    <div className={styles2.FormFooter}>
                      Password lama, wajib diisi jika kamu pernah membuat
                      password saat register ataupun pernah mengubah password
                      sebelumnya. Jika kamu lupa password sebelumnya, silahkan
                      hubungi admin Agendakota.id.
                    </div>
                  </div>
                  <div className={styles2.FormFieldBox}>
                    <div className={styles2.TitleInput}>Password Baru</div>
                    <div className={styles3.FormFieldInput}>
                      <InputForm
                        refData={fieldProfile.newPass}
                        type={"password"}
                        placeholder={
                          "Tuliskan password baru yang diinginkan min 8 karakter"
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className={styles2.FormFieldBox}>
                    <div className={styles2.TitleInput}>
                      Konfirmasi Password
                    </div>
                    <div className={styles3.FormFieldInput}>
                      <InputForm
                        refData={fieldProfile.confirmPass}
                        type={"password"}
                        placeholder={"Tuliskan kembali password baru diatas"}
                        required
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>{" "}
          </>
        )}
      </div>
    </>
  );
};

export default Setting;
