import React from "react";
import styles from "./styles/InputToogle.module.css";

const InputToogle = ({
  refData,
  checked,
  id,
  onChange = () => {},
  onInput = () => {},
  style,
}) => {
  return (
    <label style={style} className={styles.Switch}>
      <input
        id={id}
        ref={refData}
        defaultChecked={checked}
        type="checkbox"
        onChange={onChange}
        onInput={onInput}
      />
      <span className={`${styles.Slider} ${styles.Round}`}></span>
    </label>
  );
};

export default InputToogle;
