import React, { useEffect, useState } from "react";
import styles from "./styles/SpecialPage.module.css";
import Footer from "../partials/Footer";
import axios from "axios";
import Event from "../components/Event";
import Loading from "../components/Loading";
import { BiChevronRight } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import JWT from "expo-jwt";
import { SupportedAlgorithms } from "expo-jwt/dist/types/algorithms";

const mainGetRequest = async ({ path = "" }) => {
  try {
    let jwtToken = JWT.encode(
      {
        client_id: process.env.REACT_APP_ID,
        timestamp: new Date().getTime(),
      },
      process.env.REACT_APP_BACKEND_KEY,
      { algorithm: SupportedAlgorithms.HS256 }
    );
    let res = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/api/" + path,
      {
        headers: {
          "x-api-key": jwtToken,
        },
      }
    );
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    // console.log(error);
    if (error.response === undefined) {
      return {
        data: { data: [error.message] },
        status: 500,
      };
    } else {
      return {
        data: error.response,
        status: error.response.status,
      };
    }
  }
};

const SpecialPage = ({ type }) => {
  const [title, setTitle] = useState(null);
  const [events, setEvents] = useState(null);
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    if (!events) {
      if (type === "special-day") {
        mainGetRequest({ path: "active-special-days" }).then((res) => {
          if (res.status === 200) {
            let find = false;
            res.data.special_days.forEach((el) => {
              if (el.data.id == id) {
                setEvents({ events: el.events });
                setTitle(el.data.title);
                find = true;
              }
            });
            if (!find) {
              setTitle("");
              setEvents([]);
            }
          } else {
            setEvents([]);
          }
          setLoading(false);
        });
      } else if (type === "selected-event") {
        mainGetRequest({ path: "active-selected-events" }).then((res) => {
          if (res.status === 200) {
            let find = false;
            res.data.selected_events.forEach((el) => {
              if (el.data.id == id) {
                setEvents({ events: el.events });
                setTitle(el.data.title);
                find = true;
              }
            });
            if (!find) {
              setTitle("");
              setEvents([]);
            }
          } else {
            setEvents([]);
          }
          setLoading(false);
        });
      } else {
        mainGetRequest({ path: "active-selected-activities" }).then((res) => {
          if (res.status === 200) {
            let find = false;
            res.data.selected_activities.forEach((el) => {
              if (el.data.id == id) {
                setEvents({ events: el.events });
                setTitle(el.data.title);
                find = true;
              }
            });
            if (!find) {
              setTitle("");
              setEvents([]);
            }
          } else {
            setEvents([]);
          }
          setLoading(false);
        });
      }
    }
  }, [events]);

  return (
    <div className="content">
      <section className={styles.Section}>
        <div className={styles.NavigationPanel}>
          <div className={styles.NavItemSecondary}>
            <Link to="/">Home</Link>
          </div>
          <div className={styles.NavItemSecondary}>
            <BiChevronRight />
          </div>
          <div
            className={styles.NavItemPrimary}
            style={{ marginRight: "100px" }}
          >
            Special Events
          </div>
        </div>
        {loading ? (
          <div
            style={{
              marginTop: "100px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Loading />
          </div>
        ) : (
          <>
            <h3 style={{ marginTop: 0, padding: 0 }}>{title}</h3>
            <div className={styles.Content}>
              {events.length === 0 ? (
                <div className={styles.BlankState}>Data Tidak Ditemukan</div>
              ) : (
                events.events.map((event, e) => <Event data={event} key={e} />)
              )}
            </div>
          </>
        )}
      </section>
      <Footer />
    </div>
  );
};

export default SpecialPage;
