import React, { useEffect, useState } from "react";
import styles from "./styles/PopUpCheckin.module.css";
import {
  BiArrowBack,
  BiBarcodeReader,
  BiCheckCircle,
  BiError,
  BiInfoCircle,
  BiQrScan,
  BiX,
} from "react-icons/bi";
import { QrScanner } from "@yudiel/react-qr-scanner";
import Button from "../components/Button";
import InputForm from "../components/InputForm";
import axios from "axios";
import Loading from "../components/Loading";
import moment, { locale } from "moment";
import PopUp from "./PopUp";
import { useSelector } from "react-redux";
import PopUp2Factor from "./PopUp2Factor";
import JWT from "expo-jwt";
import { SupportedAlgorithms } from "expo-jwt/dist/types/algorithms";

// let codes = "";
// let load = 0;

let handleKeydown = null;

const handleSuccess = (res) => {
  return {
    data: res.data,
    status: res.status,
  };
};

const handleError = (error) => {
  // console.log(error);
  if (error.response === undefined) {
    return {
      data: { data: [error.message] },
      status: 500,
    };
  } else {
    return {
      data: error.response,
      status: error.response.status,
    };
  }
};

const checkin = async ({ eventId, token, email }) => {
  try {
    let jwtToken = JWT.encode(
      {
        client_id: process.env.REACT_APP_ID,
        email_user: email,
        timestamp: new Date().getTime(),
      },
      process.env.REACT_APP_BACKEND_KEY,
      { algorithm: SupportedAlgorithms.HS256 }
    );
    let res = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/api/checkin",
      {
        event_id: eventId,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
          "x-api-key": jwtToken,
        },
      }
    );
    return handleSuccess(res);
  } catch (error) {
    return handleError(error);
  }
};

const PopUpCheckinUser = ({
  fnClose = () => {},

  isLogin,
  fnSetLogin = () => {},
  fnSetGlobalLoding = () => {},
}) => {
  const [menu, setMenu] = useState("QR Scan");
  // const [menu, setMenu] = useState("Alert");
  const [lastMenu, setLastMenu] = useState("");
  // const [strCode, setStrCode] = useState(null);
  // const [enterCLick, setEnterClick] = useState(false);
  // const [firstLoad, setFirstLoadState] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    state: false,
    type: "",
    content: "",
  });
  const [pausedProcess, setPausedProcess] = useState(null);
  const [numberFormat, setNumFormat] = useState(Intl.NumberFormat("id-ID"));
  const appData = useSelector((state) => state.appDataReducer);
  const [isVerified, setVerify] = useState(true);
  const [verifyMsg, setVerifyMsg] = useState(
    "Mohon maaf. Kode OTP bisa didapatkan kembali minimal 2 menit dari permintaan terakhir."
  );

  const setVerified = ({ state, msg }) => {
    setVerify(state);
    if (msg) {
      setVerifyMsg(msg);
    }
  };

  const handleCheckin = (qrStr) => {
    setLoading(true);
    checkin({
      eventId:
        qrStr.split(window.location.origin + "/self-checkin/").length > 1
          ? qrStr.split(window.location.origin + "/self-checkin/")[1]
          : "",
      token: appData.accessToken,
      email: appData.userData ? appData.userData.email : "",
    }).then((res) => {
      if (res.status === 201) {
        setAlert({
          state: true,
          type: "success",
          content: (
            <div className={styles.NotifBox}>
              <div className={styles.IslandGroup}>
                Checkin
                <div className={styles.DynaminIsland}>
                  <BiCheckCircle />
                  <p>Success</p>
                </div>
              </div>
              <div className={styles.ProfileBox}>
                <img
                  src={process.env.REACT_APP_BACKEND_URL + res.data.user.photo}
                  className={styles.ProfileIcon}
                />
                <div>
                  <b>{res.data.user.name}</b>
                </div>
                <div>{res.data.user.email}</div>
                <div style={{ marginTop: "20px" }}>
                  <b>
                    {res.data.ticket.name} - {res.data.event.name}
                  </b>
                </div>
                <div>
                  {moment(res.data.purchase.created_at)
                    .locale("id")
                    .format("DD MMM Y")}
                </div>
                <div>
                  <b>Rp. {numberFormat.format(res.data.purchase.amount)},-</b>
                </div>
                <div>
                  <b>
                    {" "}
                    Checkin On{" "}
                    {moment(res.data.checkin_on)
                      .locale("id")
                      .format("DD MMM Y")}
                  </b>
                </div>
              </div>
            </div>
          ),
        });
      } else if (res.status === 401) {
        fnSetLogin(false);
        setPausedProcess(`checkin~!@!~${qrStr}`);
      } else if (res.status === 405) {
        setVerified({
          state: false,
          msg:
            res.data.data.message ===
            "Mohon lakukan verifikasi kembali. Kode OTP telah dikirimkan ke email anda"
              ? res.data.data.message
              : null,
        });
        setPausedProcess(`checkin~!@!~${qrStr}`);
      } else {
        setAlert({
          state: true,
          type: "danger",
          content:
            res.status == 404
              ? "Transaksi tidak dapat ditemukan / kadaluwarsa"
              : res.status == 403
              ? "Tiket sudah tidak berlaku / sudah digunakan"
              : "Error internal server. Silahkan coba lagi",
        });
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if (isLogin && isVerified && pausedProcess) {
      // console.log(isLogin, pausedProcess);
      handleCheckin(pausedProcess.split("~!@!~")[1]);
      if (isVerified) {
        setPausedProcess(null);
      }
    }
  }, [pausedProcess, isVerified, isLogin]);

  return (
    <>
      {!isVerified ? (
        <PopUp2Factor
          setLogin={() => {}}
          verifyState={verifyMsg}
          setVerify={setVerified}
          token={appData.accessToken}
        />
      ) : (
        <></>
      )}
      <div style={!isVerified ? { display: "none" } : {}}>
        <PopUp
          isActive
          title=""
          width="45%"
          content={
            <div className={styles.MainContainer}>
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  <div className={styles.Header}>
                    <div>User Checkin</div>
                    <BiX
                      className={styles.Right}
                      onClick={() => {
                        fnClose(false);
                        setMenu("QR Scan");
                        // codes = "";
                        // document.removeEventListener("keydown", handleKeydown);
                        fnSetGlobalLoding(true);
                        setTimeout(() => {
                          fnSetGlobalLoding(false);
                        }, 100);
                      }}
                    />
                  </div>
                  {menu === "QR Scan" ? (
                    <div
                      id="qr-scan"
                      className={styles.Center}
                      style={{ flexDirection: "column" }}
                    >
                      <div className={styles.InfoBox}>
                        <BiInfoCircle />
                        <span>
                          Checkin baru dapat dilakukan minimal H-2 dari waktu
                          start event (lihat detail event, baik di e-ticket anda
                          maupun di halaman depan) sampai dengan waktu end
                          event.
                        </span>
                      </div>
                      <QrScanner
                        onDecode={(result) => {
                          // console.log(result);
                          handleCheckin(result);
                          setLastMenu(menu);
                          setMenu("Alert");
                        }}
                        onError={(error) => {
                          // console.log(error?.message);
                          setLastMenu(menu);
                          setMenu("Alert");
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      id="alert"
                      className={`${styles.Center} ${styles.Alert}`}
                    >
                      {alert.type == "danger" ? <BiError /> : <></>}
                      <div>
                        {alert.content
                          ? alert.content
                          : "Error !!! Silahkan Coba lagi !!!"}
                      </div>
                      <Button
                        title={"Ok"}
                        fnOnClick={() => {
                          setMenu(lastMenu);
                        }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          }
        />
      </div>
    </>
  );
};

export default PopUpCheckinUser;
