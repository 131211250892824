import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import ErrorPage from "../../partials/ErrorPage";
import EventDetail from "../EventDeatail";
import JWT from "expo-jwt";
import { SupportedAlgorithms } from "expo-jwt/dist/types/algorithms";

const handleSuccess = (res) => {
  return {
    data: res.data,
    status: res.status,
  };
};

const handleError = (error) => {
  // console.log(error);
  if (error.response === undefined) {
    return {
      data: { data: [error.message] },
      status: 500,
    };
  } else {
    return {
      data: error.response,
      status: error.response.status,
    };
  }
};

const getEventData = async ({ strKey }) => {
  try {
    let jwtToken = JWT.encode(
      {
        client_id: process.env.REACT_APP_ID,
        timestamp: new Date().getTime(),
      },
      process.env.REACT_APP_BACKEND_KEY,
      { algorithm: SupportedAlgorithms.HS256 }
    );
    let res = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/api/ecs/" + strKey,
      {
        headers: {
          "x-api-key": jwtToken,
        },
      }
    );
    return handleSuccess(res);
  } catch (error) {
    return handleError(error);
  }
};

const Catcher = ({ isLogin }) => {
  const navigate = useNavigate();
  const [errorState, setErrorState] = useState(false);
  const [eventId, setEventId] = useState(null);

  useEffect(() => {
    getEventData({
      strKey:
        window.location.pathname.split("/").length > 1
          ? window.location.pathname.split("/")[1]
          : "",
    }).then((res) => {
      if (res.status === 200) {
        setEventId(res.data.data.event_id);
      } else if (res.status !== 500) {
        navigate("/404");
      } else {
        setErrorState(true);
      }
    });
  });

  return eventId ? (
    <EventDetail idParam={eventId} isLogin={isLogin} />
  ) : (
    <div className="content">
      {errorState ? (
        <ErrorPage />
      ) : (
        <div style={{ marginTop: "100px" }}>
          <Loading />
        </div>
      )}
    </div>
  );
};

export default Catcher;
